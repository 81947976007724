import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare Service Level Agreement',
  header: 'Service Level Agreement',
  description: 'Uploadcare SLA covers monthly uptime percentages, service credits, payment procedures, and more. We provide 99.9% API and 99.99% CDN Monthly Uptime Percentages respectively.'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><em parentName="p">{`Effective Date: December 7, 2018`}</em></p>
    <p>{`This Service Level Agreement (“SLA”) is a policy governing the use of Uploadcare
under the terms of the Uploadcare Terms of Service (the “Uploadcare Agreement”)
between Uploadcare, Inc. (“Uploadcare”, “us” or “we”) and users of Uploadcare’s
services (“you”). This SLA applies separately to each account using Uploadcare.
Unless otherwise provided herein, this SLA is subject to the terms of the
Uploadcare Agreement and capitalized terms will have the meaning specified in
the Uploadcare Agreement. We reserve the right to change the terms of this SLA
in accordance with the Uploadcare Agreement.`}</p>
    <h2 {...{
      "id": "service-commitment"
    }}><a parentName="h2" {...{
        "href": "#service-commitment"
      }}>{`Service Commitment`}</a></h2>
    <p>{`Uploadcare will use commercially reasonable efforts to make Uploadcare Services
available with the following Monthly Uptime Percentages:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Upload API`}</strong>{` at least `}<strong parentName="li">{`99.9%`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`REST API`}</strong>{` at least `}<strong parentName="li">{`99.9%`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`CDN`}</strong>{` at least `}<strong parentName="li">{`99.99%`}</strong></li>
    </ul>
    <p>{`during any monthly billing cycle (the “Service Commitment”).`}</p>
    <p><strong parentName="p">{`Upload API`}</strong>{` is considered available when the Uploadcare service is able to
receive new files from the end users.`}</p>
    <p><strong parentName="p">{`REST API`}</strong>{` is considered available when the Uploadcare service is able to
receive and process requests sent to REST API endpoint.`}</p>
    <p><strong parentName="p">{`CDN`}</strong>{` is considered available when Uploadcare service is able to process and
deliver files stored in Uploadcare storage.`}</p>
    <p>{`In the event Uploadcare does not meet the Service Commitment, you will be
eligible to receive a Service Credit as described below.`}</p>
    <h2 {...{
      "id": "definitions"
    }}><a parentName="h2" {...{
        "href": "#definitions"
      }}>{`Definitions`}</a></h2>
    <p>{`“Monthly Uptime Percentage” is calculated by subtracting from 100% the
percentage of minutes during the month in which Uploadcare, as applicable, was
unavailable.`}</p>
    <p>{`A “Service Credit” is a US dollar credit, calculated as set forth below, that we
may credit back to an eligible account.`}</p>
    <p>{`“Scheduled Maintenance“ means Uploadcare’s scheduled routine maintenance of the
Services for which Uploadcare notifies you at least seventy two (72) hours in
advance. Scheduled Maintenance will not exceed eight (8) hours per month.`}</p>
    <h2 {...{
      "id": "exclusions"
    }}><a parentName="h2" {...{
        "href": "#exclusions"
      }}>{`Exclusions`}</a></h2>
    <p>{`The calculation of “Monthly Uptime Percentage“ will not include unavailability
to the extent due to:`}</p>
    <ul>
      <li parentName="ul">{`(a) use of the Services by you in a manner not authorized in Uploadcare
Agreement or the applicable Documentation;`}</li>
      <li parentName="ul">{`(b) general Internet problems, force majeure events or other factors outside
of Uploadcare’s reasonable control;`}</li>
      <li parentName="ul">{`(c) your and end users’ equipment, software, network connections or other
infrastructure;`}</li>
      <li parentName="ul">{`(d) third party systems, acts or omissions;`}</li>
      <li parentName="ul">{`(e) Scheduled Maintenance.`}</li>
    </ul>
    <h2 {...{
      "id": "service-credits"
    }}><a parentName="h2" {...{
        "href": "#service-credits"
      }}>{`Service Credits`}</a></h2>
    <p>{`Service Credits are calculated as a percentage of the total charges paid by you
to Uploadcare in accordance with the schedule below.`}</p>
    <h3 {...{
      "id": "upload-api"
    }}><a parentName="h3" {...{
        "href": "#upload-api"
      }}>{`Upload API`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Monthly Uptime Percentage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Service Credit Percentage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than 99.9% but equal to or greater than 99.0%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than 99.0%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30%`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "rest-api"
    }}><a parentName="h3" {...{
        "href": "#rest-api"
      }}>{`REST API`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Monthly Uptime Percentage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Service Credit Percentage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than 99.9% but equal to or greater than 99.0%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than 99.0%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30%`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "cdn"
    }}><a parentName="h3" {...{
        "href": "#cdn"
      }}>{`CDN`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Monthly Uptime Percentage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Service Credit Percentage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than 99.99% but equal to or greater than 99.0%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than 99.0%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30%`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`We will apply any Service Credits only against future Uploadcare payments
otherwise due from you. Service Credits will not entitle you to any refund or
other payment from Uploadcare. A Service Credit will be applicable and issued
only if the credit amount for the applicable monthly billing cycle is greater
than one dollar (1 USD). Service Credits may not be transferred or applied to
any other account. Your sole and exclusive remedy for any unavailability,
non-performance, or other failure by us to provide Uploadcare service is the
receipt of a Service Credit (if eligible) in accordance with the terms of this
SLA.`}</p>
    <h2 {...{
      "id": "credit-request-and-payment-procedures"
    }}><a parentName="h2" {...{
        "href": "#credit-request-and-payment-procedures"
      }}>{`Credit Request and Payment Procedures`}</a></h2>
    <p>{`To receive a Service Credit, you must submit a claim by sending a message to
`}<a parentName="p" {...{
        "href": "mailto:help@uploadcare.com"
      }}>{`help@uploadcare.com`}</a>{` e-mail address. To be eligible, the credit request must be
received by us by the end of the second billing cycle after which the incident
occurred and must include:`}</p>
    <ul>
      <li parentName="ul">{`the words “SLA Credit Request” in the subject line;`}</li>
      <li parentName="ul">{`the dates and times of each Unavailability incident that you are claiming;`}</li>
      <li parentName="ul">{`login name that you use to access the Uploadcare dashboard; and`}</li>
      <li parentName="ul">{`your request logs that document the errors and corroborate your claimed outage
(any confidential or sensitive information in these logs should be removed or
replaced with asterisks).`}</li>
    </ul>
    <p>{`If the Monthly Uptime Percentage of such request is confirmed by us and is less
than the Service Commitment, then we will issue the Service Credit to you within
one billing cycle following the month in which your request is confirmed by us.
Your failure to provide the request and other information as required above will
disqualify you from receiving a Service Credit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      